import { resolveComponent as _resolveComponent, createTextVNode as _createTextVNode, createVNode as _createVNode } from "vue";
import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.array.push.js";
import { mixins } from "@/plugins/mixins";
import { ElLoading, ElMessage, ElMessageBox } from "element-plus";
import 'element-plus/es/components/loading/style/css'
import 'element-plus/es/components/message/style/css'
import 'element-plus/es/components/message-box/style/css'

import ChargingStationMap from "@/components/ChargingStationMap.vue";
export default {
  name: "chargingStation",
  mixins: [mixins],
  components: {
    ChargingStationMap
  },
  data() {
    return {
      currentId: null,
      detailDrawer: false,
      isAdd: false,
      parkType: "",
      address: null,
      type: 'LOOK',
      switchLoading: false,
      columns: [{
        label: '充电站名称',
        prop: 'name'
      }, {
        label: '充电站厂商',
        prop: 'manufacturer'
      }, {
        label: '所属区域',
        prop: 'areaName'
      }, {
        label: '充电站地址',
        prop: 'address'
      }, {
        label: '联系人',
        prop: 'contacts'
      }, {
        label: '联系电话',
        prop: 'telephone'
      }, {
        label: '定位',
        customRender: props => {
          return _createVNode(_resolveComponent("el-link"), {
            "type": "primary",
            "underline": false,
            "onClick": () => {
              this.addMarker(props.row);
            }
          }, {
            default: () => [_createTextVNode("\u5145\u7535\u7AD9\u4F4D\u7F6E"), _createVNode("i", {
              "className": "el-icon-view el-icon--right"
            }, null)]
          });
        }
      }, {
        label: '状态',
        customRender: props => {
          if (this.$hasPermission('chargingStation_status')) {
            return _createVNode(_resolveComponent("el-switch"), {
              "modelValue": props.row.status,
              "onUpdate:modelValue": $event => props.row.status = $event,
              "loading": this.switchLoading,
              "before-change": () => {
                return new Promise((resolve, reject) => {
                  this.changeEnabled(props.row, resolve, reject);
                });
              }
            }, null);
          } else {
            return props.row.status === 0 ? '启用' : '禁用';
          }
        },
        width: '110'
      }],
      topButtons: [{
        name: '新增',
        code: 'chargingStation_add',
        type: 'primary',
        click: this.add,
        permissions: ['chargingStation_add']
      }],
      linkButtons: [{
        name: '删除',
        code: 'chargingStation_del',
        type: 'danger',
        click: this.Del,
        permissions: ['chargingStation_del']
      }, {
        name: '编辑',
        code: 'chargingStation_edit',
        click: this.Edit,
        permissions: ['chargingStation_edit']
      }],
      searchItems: [{
        prop: 'name',
        label: '充电站名称'
      }, {
        type: 'cascader',
        prop: 'areaCode',
        label: '区域',
        props: {
          checkStrictly: true,
          emitPath: false,
          value: 'id',
          label: 'name',
          lazy: true,
          lazyLoad: (node, resolve) => {
            const {
              level
            } = node;
            if (level === 0) {
              this.$api.uac.area.list({}).then(res => {
                if (res.code === 200) {
                  resolve(res.data);
                } else {
                  resolve([]);
                }
              });
            } else {
              resolve([]);
            }
          }
        }
      }]
    };
  },
  methods: {
    changeEnabled(row, resolve, reject) {
      this.switchLoading = true;
      const callBack = res => {
        if (res.code === 200) {
          ElMessage({
            message: res.message,
            type: 'success'
          });
          return resolve(true);
        } else {
          ElMessage.error(res.message);
          return reject(new Error('Error'));
        }
      };
      let parms = {};
      if (row.status) {
        parms = {
          status: 0,
          id: row.id
        };
        this.$api.business.chargingStation.status(parms).then(callBack).finally(() => this.switchLoading = false);
      } else {
        parms = {
          status: 1,
          id: row.id
        };
        this.$api.business.chargingStation.status(parms).then(callBack).finally(() => this.switchLoading = false);
      }
    },
    addMarker(row) {
      this.parkType = '定位范围';
      this.address = {
        lng: row.longitude,
        lat: row.latitude,
        add: row.address,
        title: row.name
      };
      this.isAdd = true;
    },
    add() {
      this.$router.push('/chargingStationAdd');
    },
    Edit(row) {
      this.$router.push({
        path: '/chargingStationAdd',
        query: {
          id: row.id
        }
      });
    },
    Del(row) {
      ElMessageBox.confirm('确认要删除该充电站吗?', '提示', {
        type: 'warning'
      }).then(() => {
        const loading = ElLoading.service();
        this.$api.business.chargingStation.del(row.id).then(res => {
          if (res.code === 200) {
            ElMessage({
              message: res.message,
              type: 'success'
            });
            this.$refs.tablePage.fetch();
          } else {
            ElMessage.error(res.message);
          }
        }).finally(() => {
          loading.close();
        });
      });
    }
  },
  beforeMount() {}
};